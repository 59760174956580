(function(window, document, version, callback) {
  var j, d;
  var loaded = false;
  if (!(j = window.jQuery) || version > j.fn.jquery || callback(j, loaded)) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://ajax.googleapis.com/ajax/libs/jquery/2.0.0/jquery.min.js";
      script.onload = script.onreadystatechange = function() {
          if (!loaded && (!(d = this.readyState) || d == "loaded" || d == "complete")) {
              callback((j = window.jQuery).noConflict(1), loaded = true);
              j(script).remove();
          }
      };
      (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script);
  }
})(window, document, "2.0", function(jQuery, jquery_loaded) {

  // Widget code here
  var smsEnabled = false;
  var alive5_mode = '{{ALIVE5_MODE}}';
  var widgetFiles = ['widget-min.js'];
  if(alive5_mode === 'dev'){
    widgetFiles = ['helpers.js', 'widget.js'];
  }
  if (document.querySelector('#a5widget') && document.querySelector('#a5widget').attributes["data-sms_enabled"]) {
    smsEnabled = document.querySelector('#a5widget').attributes["data-sms_enabled"].value === 'true';
  }

  function loadFiles(loadID){
    widgetFiles.map(function(widgetFile){
      console.log('load file ', widgetFile);
      jQuery.getScript('{{RESOURCE_URL}}/js/' + widgetFile, function(s, status){
        console.log('Widget module loaded ' + loadID + ' ' + alive5_mode);
      });
    });
  }

  if (jQuery) {
    console.log('jQuery loaded successfully!');

    if (typeof window.orientation !== 'undefined' && smsEnabled) {
      jQuery.getScript('{{RESOURCE_URL}}/js/sms.js', function(s, status){
        console.log("sms module loaded");
      });
    } else {
      var fb_page_id = undefined;
      var org_name = undefined;
      if (document.querySelector('#a5widget') && document.querySelector('#a5widget').attributes["data-page_id"]) {
        fb_page_id = document.querySelector('#a5widget').attributes["data-page_id"].value;
      }

      if (document.querySelector('#a5widget') && document.querySelector('#a5widget').attributes["data-alive5_org"]) {
        org_name = document.querySelector('#a5widget').attributes["data-alive5_org"].value;
      }

      var BACKEND_URL_V2 = '{{BACKEND_URL_V2}}';
      if(fb_page_id && org_name && typeof window.orientation === 'undefined') {
        jQuery.get(BACKEND_URL_V2 + '/admin/valid-facebook-page?fbPageId=' + fb_page_id + '&org_name=' + org_name, function(response){
          if (response.valid) {
            jQuery('body').append(`<iframe style="display:none;" src="{{RESOURCE_URL}}/fbstatus.html" />`);
            window.addEventListener("message", (response) => {
              if(response.data.a5fb) {
                if (response.data.a5fb.status !== 'unknown') {
                  jQuery.getScript('{{RESOURCE_URL}}/js/fb.js', function(s, status){
                    console.log("fb module loaded");
                  });
                } else {
                  loadFiles('c');
                }
              }
            } , false);
          } else {
            loadFiles('b');
          }
        });
      } else {
        loadFiles('a');
      }
    }
  }
});